<template>
  <v-toolbar class="mb-5" flat>
    <v-toolbar-title v-text="title"></v-toolbar-title>
    <v-spacer/>
    <div>
      <v-btn :loading="isLoading" @click="submitItem" color="primary" fab v-if="submitEnabled()">
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
      <v-btn @click="refresh" class="ml-sm-2 lighten-2" fab v-if="refreshEnabled()">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-btn @click="copyItem" class="ml-sm-2" fab color="primary" v-if="copyEnabled()">
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
      <v-btn @click="downloadItem" v-if="downloadEnabled()" class="ml-sm-2" fab color="primary"
             :loading="downloadLoading" :disabled="downloadLoading">
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-btn @click="handleDownloadLock" v-if="downloadLockEnabled()" class="ml-sm-2" fab color="primary"
             :loading="downloadLockLoading" :disabled="downloadLockLoading">
        <v-icon>mdi-download-lock</v-icon>
      </v-btn>
      <v-btn @click="resetItem" class="ml-sm-2 lighten-2" color="primary" fab v-if="resetEnabled()">
        <v-icon>mdi-restore</v-icon>
      </v-btn>
      <v-btn @click="editItem" class="ml-sm-2" fab color="primary" v-if="editEnabled()">
        <v-icon>mdi-pencil-circle</v-icon>
      </v-btn>
      <v-btn @click="confirmDelete = true" class="ml-sm-2" color="error" fab v-if="deleteEnabled()">
        <v-icon>mdi-delete-circle</v-icon>
      </v-btn>
      <v-btn @click="addItem" class="ml-sm-2" fab color="primary" v-if="addEnabled()">
        <v-icon>mdi-plus-circle</v-icon>
      </v-btn>
      <v-btn @click="cancel" class="ml-sm-2" fab color="red" v-if="cancelItemEnabled()">
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
      <v-btn @click="correct" class="ml-sm-2" fab color="red" v-if="correctItemEnabled()">
        <v-icon>mdi-file-document-edit</v-icon>
      </v-btn>
      <v-btn @click="handleRegenerateDocument" v-if="regenerateDocumentEnabled()" class="ml-sm-2" fab color="primary"
             :loading="regenerateDocumentLoading" :disabled="regenerateDocumentLoading">
        <v-icon>mdi-file-cog</v-icon>
      </v-btn>
      <v-btn @click="approveInvoice" class="ml-sm-2" :loading="isLoading" color="primary" fab v-if="invoiceApprovalEnabled()">
        <v-icon>mdi-currency-eur</v-icon>
      </v-btn>
    </div>
    <ConfirmDelete
      :handle-delete="handleDelete"
      :visible="confirmDelete"
      @close="confirmDelete = false"
      v-if="handleDelete"
    />
  </v-toolbar>
</template>

<script>
import ConfirmDelete from './ConfirmDelete';

export default {
  name: 'Toolbar',
  components: {
    ConfirmDelete,
  },
  data() {
    return {
      confirmDelete: false,
      confirmDownloadLock: false
    };
  },
  props: {
    handleSubmit: {
      type: Function,
      required: false
    },
    enableSubmit: {
      type: Boolean,
      required: false,
      default: () => true
    },
    handleReset: {
      type: Function,
      required: false
    },
    enableReset: {
      type: Boolean,
      required: false,
      default: () => true
    },
    handleDelete: {
      type: Function,
      required: false
    },
    enableDelete: {
      type: Boolean,
      required: false,
      default: () => true
    },
    handleAdd: {
      type: Function,
      required: false
    },
    enableAdd: {
      type: Boolean,
      required: false,
      default: () => true
    },
    handleEdit: {
      type: Function,
      required: false
    },
    enableEdit: {
      type: Boolean,
      required: false,
      default: () => true
    },
    handleCopy: {
      type: Function,
      required: false
    },
    enableCopy: {
      type: Boolean,
      required: false,
      default: () => true
    },
    handleDownload: {
      type: Function,
      required: false
    },
    enableDownload: {
      type: Boolean,
      required: false,
      default: () => true
    },
    enableInvoiceApproval: {
      type: Boolean,
      required: false,
      default: () => false
    },
    downloadLoading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    handleDownloadLock: {
      type: Function,
      required: false
    },
    enableDownloadLock: {
      type: Boolean,
      required: false,
      default: () => true
    },
    downloadLockLoading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    handleRegenerateDocument: {
      type: Function,
      required: false
    },
    enableRegenerateDocument: {
      type: Boolean,
      required: false,
      default: () => true
    },
    regenerateDocumentLoading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    handleCancel: {
      type: Function,
      required: false,
    },
    handleCorrect: {
      type: Function,
      required: false,
    },
    handleRefresh: {
      type: Function,
      required: false,
    },
    approveInvoice: {
      type: Function,
      required: false,
    },
    enableCancel: {
      type: Boolean,
      required: false,
      default: () => false
    },
    enableRefresh: {
      type: Boolean,
      required: false,
      default: () => false
    },
    enableCorrect: {
      type: Boolean,
      required: false,
      default: () => false
    },
    title: {
      type: String,
      required: false
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  methods: {
    addItem() {
      if (this.handleAdd) {
        this.handleAdd();
      }
    },
    addEnabled() {
      return (this.handleAdd && this.enableAdd);
    },
    editItem() {
      if (this.handleEdit) {
        this.handleEdit();
      }
    },
    editEnabled() {
      return (this.handleEdit && this.enableEdit);
    },
    submitItem() {
      if (this.handleSubmit) {
        this.handleSubmit();
      }
    },
    submitEnabled() {
      return (this.handleSubmit && this.enableSubmit);
    },
    invoiceApprovalEnabled() {
      return (this.invoiceApprovalEnabled && this.approveInvoice)
    },
    resetItem() {
      if (this.handleReset) {
        this.handleReset();
      }
    },
    resetEnabled() {
      return (this.handleReset && this.enableReset);
    },
    copyItem() {
      if (this.handleCopy) {
        this.handleCopy();
      }
    },
    cancel() {
      if (this.handleCancel) {
        this.handleCancel();
      }
    },
    correct() {
      if (this.handleCorrect) {
        this.handleCorrect();
      }
    },
    refresh() {
      if (this.handleRefresh) {
        this.handleRefresh();
      }
    },
    copyEnabled() {
      return (this.handleCopy && this.enableCopy);
    },
    downloadItem() {
      if (this.handleDownload) {
        this.handleDownload();
      }
    },
    downloadEnabled() {
      return (this.handleDownload && this.enableDownload);
    },
    downloadLockEnabled() {
      return (this.handleDownloadLock && this.enableDownloadLock);
    },
    regenerateDocumentEnabled() {
      return (this.handleRegenerateDocument && this.enableRegenerateDocument);
    },
    deleteEnabled() {
      return (this.handleDelete && this.enableDelete);
    },
    cancelItemEnabled() {
      return (this.handleCancel && this.enableCancel)
    },
    correctItemEnabled() {
      return (this.handleCorrect && this.enableCorrect)
    },
    refreshEnabled() {
      return (this.handleRefresh && this.enableRefresh)
    }
  }
};
</script>
