<template>
  <div>
    <Toolbar :handle-delete="del"  :handle-edit="() => editHandler(item)">
      <template slot="left">
        <v-toolbar-title v-if="item">{{ `${$options.servicePrefix} ${item['@id']}` }}</v-toolbar-title>
      </template>
    </Toolbar>

    <div v-if="item">
      <v-card max-width="860px" class="mt-4 mx-auto">
        <v-tabs v-model="tab" centered icons-and-text>
          <v-tabs-slider></v-tabs-slider>
          <v-tab>
            {{ $t('General') }}
            <v-icon>mdi-account</v-icon>
          </v-tab>
          <v-tab>
            {{ $t('Settings') }}
            <v-icon>mdi-timeline-clock</v-icon>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card flat class="mt-5">
              <v-card-text class="text-center">
                <h1 class="font-weight-light mb-3 black--text"> {{ item.name }} </h1>
                <h2 class="mb-1 grey--text">{{ item.domain }}</h2>
                <h3>{{ $t('mailFromMail') }} : {{ item.mailFromMail }}<br/>{{ $t('mailFromName') }} : {{ item.mailFromName }}</h3>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-container>
              <v-row dense>
                <v-col cols="12">
                  <v-card class="mt-5" color="blue darken-4" flat dark>
                    <v-card-title class="headline">{{ $t('Seminar-Settings') }}</v-card-title>
                    <v-card-text>
                      <div v-if="item.seminarSettings">

                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn text v-if="item.seminarSettings"
                             :to="{name: 'TenantSeminarSettingUpdate', params: {id: item.seminarSettings}}">
                        Editieren
                      </v-btn>
                      <v-btn text v-else :to="{ name: 'TenantSeminarSettingCreate' }">Erstellen</v-btn>
                    </v-card-actions>
                  </v-card>
                  <v-card class="mt-5" color="blue darken-4" flat dark>
                    <v-card-title class="headline">{{ $t('Account-Settings') }}</v-card-title>
                    <v-card-text>
                      <div v-if="item.accountingSettings">

                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn text v-if="item.accountingSettings"
                             :to="{name: 'TenantAccountSettingUpdate', params: {id: item.accountingSettings}}">
                        Editieren
                      </v-btn>
                      <v-btn text v-else :to="{ name: 'TenantAccountSettingCreate' }">Erstellen</v-btn>
                    </v-card-actions>
                  </v-card>
                  <v-card class="mt-5" color="blue darken-4" flat dark>
                    <v-card-title class="headline">{{ $t('Company-Settings') }}</v-card-title>
                    <v-card-text>
                      <div v-if="item.accountingSettings">

                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn text v-if="item.companySettings"
                             :to="{name: 'TenantCompanySettingUpdate', params: {id: item.companySettings}}">
                        Editieren
                      </v-btn>
                      <v-btn text v-else :to="{ name: 'TenantCompanySettingCreate' }">Erstellen</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {mapFields} from 'vuex-map-fields';
import ShowMixin from '../../mixins/ShowMixin';
import Toolbar from '../../components/Toolbar';

const servicePrefix = 'Tenant';

export default {
  name: 'TenantShow',
  servicePrefix,
  components: {
    Toolbar,
  },
  mixins: [ShowMixin],
  data() {
    return {
      tab: true,
      selected: [],
    };
  },
  computed: {
    ...mapFields('tenant', {
      isLoading: 'isLoading',
    }),
    ...mapGetters('tenant', {find: 'find'}),
  },
  methods: {
    ...mapActions('tenant', {
      deleteItem: 'del',
      reset: 'reset',
      retrieve: 'load',
    }),
    log(message) {
      console.log(message);
    },
  },
};
</script>
