<template>
  <v-dialog persistent v-model="show" width="300">
    <v-card>
      <v-card-title>{{ $t('Are you sure you want to delete this item?') }}</v-card-title>
      <v-card-actions>
        <v-spacer/>
        <v-btn @click="handleDelete" color="error darken-1">
          {{ $t('Delete') }}
        </v-btn>
        <v-btn @click.stop="show = false" color="secondary darken-1" text>
          {{ $t('Cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDelete',
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: () => false
    },
    handleDelete: {
      type: Function,
      required: true
    }
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      }
    }
  }
};
</script>
