import NotificationMixin from './NotificationMixin';
import { formatDateTime } from '../utils/dates';

export default {
  mixins: [NotificationMixin],
  created () {
    this.retrieve(decodeURIComponent(this.$route.params.id));
  },
  computed: {
    item () {
      return this.find(decodeURIComponent(this.$route.params.id));
    }
  },
  methods: {
    del () {
      this.deleteItem(this.item).then(() => {
        this.showMessage(`${this.item['@id']} deleted.`);
        this.$router
          .push({ name: `${this.$options.servicePrefix}List` })
          .catch(() => {});
        if (this.afterDelete) {
          this.afterDelete();
        }
      });
    },
    editHandler (item) {
      if(typeof item === "undefined" && typeof this.item !== "undefined"){
        item = this.item;
      }
      this.$router.push({
        name: `${this.$options.servicePrefix}Update`,
        params: { id: item['@id'] }
      });
    },
    formatDateTime
  },
  watch: {
    error (message) {
      message && this.showError(message);
    },
    deleteError (message) {
      message && this.showError(message);
    }
  }
};
